/* .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
} */

.footer .content {
    margin: 0 auto;
    max-width: 1680px;
    background-color: #FFFFFF;
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-menu {
    width: 60%;
}

.footer-menu .company {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #777777;
}

.footer-menu .company-en {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #777777;
    margin-bottom: 20px;
}

.footer-menu .copyright-en {
    font-family: Montserrat;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #B5B5B5;
}
.footer-menu .gongan-icon {
    width: 12px;
    height: 12px;
}

.footer-menu .copyright .text {
    cursor: pointer;
}
.footer-menu .copyright,.tips {
    font-family: Montserrat;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #B5B5B5;
}

.separator{
    border-left: 1px solid #E0E0E0;
}

.footer-action {
    height: 100%;
    display: flex;
    width: 200px;
    justify-content: space-around;
    align-items: center;
}

