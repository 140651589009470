.logo-container {
    color: #D0121B;
    display: flex;
    width: 350px;
    align-items: center;
    justify-content: flex-start;
}

.logo {
    width : 48px;
    height: 48px;
    margin-right: 10px;
}

.desc {
    color: #D0121B;
    height: 40px;
}

.desc .brand {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

.desc .slogan {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
}