.contact-container {
  display: flex;
}
.contact-container .info-wrap {
  padding-top: 90px;
  width: 288px;
  padding-right: 202px;
}
.contact-container .title {
  font-size: 36px;
  font-weight: bold;
}
.contact-container .sub-title {
  font-size: 16px;
  font-weight: bold;
  color: #d0121b;
}

.contact-container p {
  font-size: 14px;
  color: #4e4e4e;
  line-height: 24px;
}

.contact-container .map {
  /* margin-top: -24px; */
  width: 70%;
}
.contact-container .map img{
  border: 1px solid #e4e4e4;
  width: 100%;
}

.contact-container .info-item .line {
  margin-top: 60px;
  margin-bottom: 20px;
}
