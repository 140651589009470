.home-container {
    display: flex;
    padding: 80px 24px;
    justify-content: space-between;
}

.left {
    width: 40%
}

.left .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
}

.left .mission {
    font-size: 20px;
    line-height: 24px;
    color: #D0121B;
    margin-bottom: 8px;
}

.left .slogan {
    font-weight: bold;
    font-size: 56px;
    line-height: 66px;
    color: #131212;
    margin-bottom: 48px;
}

.left .detail {
    color: #131212;
    opacity: 0.5;
    font-family: Noto Sans S Chinese;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}
.right {
    width: 50%
}