.games-container .games-header {
  padding-top: 66px;
  font-size: 36px;
  font-weight: bold;
}

.games-container .content-wrap {
  padding-top: 60px;
}

.games-container .content {
  display: inline-block;
  width: calc(50% - 37.5px);
  /* padding-right: 75px; */
}
.games-container .content-wrap .content:first-child {
  display: inline-block;
  width: calc(50% - 40px);
  padding-right: 75px;
}

.games-container .content .name-en {
  margin-top: 35px;
  font-size: 16px;
}
.games-container .content .name-zh {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 8px;
}

.games-container .operation-area {
  height: 60px;
  text-align: right;
  padding: 10px 0;
}

.games-container .content img {
  width: 100%;
  /* height: 400px; */
}

.games-container .content-wrap .content .desc {
  color: #888;
  font-size: 14px;
  margin-bottom: 16px;
  background-color: #f0f0f2;
  height: auto;
  padding: 8px;
}

.games-container .content-wrap .content .desc .desc_label {
  color: #888;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 1.5;
}

.games-container .content-wrap .content .desc .tags {
  display: flex;
  align-items: center;
  gap: 4px;
}

.games-container .content-wrap .content .desc .tags span {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 8px;
  background: #fff;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  color: #8e8e8d;
}
