.page-container {
  /* padding: 24px; */
  /* max-width: 1680px; */
  width: 1680px;
  margin: 0 auto;
  flex-grow: 1;
}

a {
  text-decoration: none;
}

.app-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}