.header {
    height: 128px;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    /* padding: 0 24px; */
}

.header .content {
    height: 128px;
    max-width: 1680px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-menu {
    width: 280px;
    display: flex;
}

.header-menu a {
    color: #757575;
}

.header-menu hr {
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
}

.header-menu .active {
    color: #D0121B;
    font-weight: bold;
}